import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Privacy from './Privacy'
import reportWebVitals from './reportWebVitals'
import { AlertProvider } from './context/AlertContext'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

declare global {
  interface Window {
    adsbygoogle: any
  }
}

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <AlertProvider>
            <App />
          </AlertProvider>
        }
      />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
