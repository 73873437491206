export const WORDS = [
  'ΑΓΑΘΟ',
  'ΑΓΑΠΗ',
  'ΓΕΛΑΩ',
  'ΠΕΡΛΑ',
  'ΘΛΙΨΗ',
  'ΦΡΕΝΟ',
  'ΑΞΙΖΩ',
  'ΝΙΟΤΗ',
  'ΥΓΡΟΣ',
  'ΑΓΡΙΟ',
  'ΤΟΚΟΣ',
  'ΣΑΡΚΑ',
  'ΚΟΙΝΟ',
  'ΚΟΥΛΟ',
  'ΣΚΕΠΗ',
  'ΠΑΓΙΟ',
  'ΓΑΜΟΣ',
  'ΣΚΑΛΑ',
  'ΚΑΛΟΣ',
  'ΓΡΑΦΩ',
  'ΖΥΘΟΣ',
  'ΒΛΕΠΩ',
  'ΜΠΟΧΑ',
  'ΚΟΜΜΑ',
  'ΚΡΕΑΣ',
  'ΓΝΩΜΗ',
  'ΓΟΦΟΣ',
  'ΜΠΟΤΑ',
  'ΒΡΩΜΗ',
  'ΠΙΕΤΑ',
  'ΘΕΡΜΗ',
  'ΝΥΜΦΗ',
  'ΣΟΦΙΑ',
  'ΟΡΝΙΟ',
  'ΡΙΣΚΟ',
  'ΚΑΛΠΗ',
  'ΠΛΟΚΗ',
  'ΟΨΕΩΣ',
  'ΨΟΦΑΩ',
  'ΕΙΔΟΣ',
  'ΑΣΒΟΣ',
  'ΑΗΔΙΑ',
  'ΜΕΡΙΑ',
  'ΤΣΑΠΑ',
  'ΒΟΡΑΣ',
  'ΒΡΑΣΗ',
  'ΔΙΑΝΑ',
  'ΤΕΦΡΑ',
  'ΠΕΖΟΣ',
  'ΤΟΠΙΟ',
  'ΑΚΡΟΝ',
  'ΠΕΝΤΕ',
  'ΚΑΚΑΟ',
  'ΣΩΡΟΣ',
  'ΓΥΜΝΟ',
  'ΦΡΥΔΙ',
  'ΦΥΤΡΑ',
  'ΕΘΙΜΟ',
  'ΓΩΝΙΑ',
  'ΖΕΣΤΟ',
  'ΣΤΑΝΗ',
  'ΨΕΙΡΑ',
  'ΑΠΕΧΩ',
  'ΚΡΙΣΗ',
  'ΚΡΥΟΣ',
  'ΣΤΥΒΩ',
  'ΑΣΤΟΣ',
  'ΕΞΩΘΩ',
  'ΠΟΤΗΣ',
  'ΚΟΨΙΑ',
  'ΛΙΑΝΑ',
  'ΦΟΡΑΩ',
  'ΠΛΑΘΩ',
  'ΧΡΕΟΣ',
  'ΑΘΕΛΑ',
  'ΠΕΤΣΑ',
  'ΠΡΕΦΑ',
  'ΛΥΤΟΣ',
  'ΒΑΘΥΣ',
  'ΟΡΥΞΗ',
  'ΡΟΜΠΑ',
  'ΛΕΚΕΣ',
  'ΜΕΙΟΝ',
  'ΞΕΦΤΩ',
  'ΜΟΝΟΣ',
  'ΟΔΗΓΩ',
  'ΚΡΙΝΩ',
  'ΤΡΟΦΗ',
  'ΕΛΕΟΣ',
  'ΑΔΟΞΑ',
  'ΑΓΑΠΩ',
  'ΦΑΤΝΗ',
  'ΜΟΔΑΣ',
  'ΠΑΡΚΟ',
  'ΦΑΚΕΣ',
  'ΒΛΗΜΑ',
  'ΠΑΤΕΡ',
  'ΤΙΜΕΣ',
  'ΩΘΗΣΗ',
  'ΠΕΝΙΑ',
  'ΗΧΕΙΟ',
  'ΛΥΤΗΣ',
  'ΓΛΥΚΟ',
  'ΜΑΥΡΟ',
  'ΑΣΗΜΙ',
  'ΧΙΟΝΙ',
  'ΟΥΣΙΑ',
  'ΔΙΩΞΗ',
  'ΣΤΕΚΩ',
  'ΒΩΛΟΣ',
  'ΣΧΟΛΗ',
  'ΛΑΙΚΑ',
  'ΜΑΓΙΑ',
  'ΑΓΝΟΣ',
  'ΚΛΑΜΑ',
  'ΛΑΘΟΣ',
  'ΠΕΝΣΑ',
  'ΜΕΡΑΣ',
  'ΦΛΟΓΑ',
  'ΔΕΛΤΑ',
  'ΠΑΘΩΝ',
  'ΚΑΔΟΣ',
  'ΑΠΕΞΩ',
  'ΕΛΑΙΟ',
  'ΧΗΛΟΣ',
  'ΠΛΑΤΟ',
  'ΣΒΗΝΩ',
  'ΝΩΜΟΣ',
  'ΔΟΓΜΑ',
  'ΦΥΓΑΣ',
  'ΜΠΕΤΟ',
  'ΛΑΓΟΣ',
  'ΣΚΥΒΩ',
  'ΙΣΤΙΟ',
  'ΜΠΕΣΑ',
  'ΑΕΤΟΣ',
  'ΠΑΣΤΟ',
  'ΦΥΛΛΟ',
  'ΜΠΥΡΑ',
  'ΣΤΕΝΟ',
  'ΤΡΕΛΑ',
  'ΜΙΤΟΣ',
  'ΝΟΗΜΑ',
  'ΠΕΥΚΟ',
  'ΑΘΕΟΣ',
  'ΝΩΡΙΣ',
  'ΦΙΛΑΩ',
  'ΣΤΙΛΟ',
  'ΗΡΕΜΑ',
  'ΝΩΠΟΣ',
  'ΑΛΟΓΟ',
  'ΔΥΤΗΣ',
  'ΦΑΡΟΣ',
  'ΧΥΤΡΑ',
  'ΓΥΨΟΣ',
  'ΦΟΙΤΩ',
  'ΑΡΩΜΑ',
  'ΧΑΝΟΣ',
  'ΙΣΧΙΟ',
  'ΓΑΜΟΣ',
  'ΜΟΝΟΣ',
  'ΣΑΛΟΣ',
  'ΠΛΕΞΗ',
  'ΠΑΡΟΝ',
  'ΛΑΥΡΑ',
  'ΝΗΠΙΟ',
  'ΛΟΥΦΑ',
  'ΓΟΥΔΙ',
  'ΛΟΓΟΥ',
  'ΝΟΕΡΑ',
  'ΦΟΡΜΑ',
  'ΣΙΓΜΑ',
  'ΜΥΑΛΟ',
  'ΗΔΟΝΗ',
  'ΠΟΡΤΑ',
  'ΟΜΙΛΩ',
  'ΓΕΝΝΑ',
  'ΠΑΙΔΙ',
  'ΔΕΙΛΑ',
  'ΠΛΥΣΗ',
  'ΝΟΤΙΑ',
  'ΖΟΡΙΑ',
  'ΠΙΑΝΩ',
  'ΤΜΗΜΑ',
  'ΜΕΤΡΟ',
  'ΞΕΡΟΣ',
  'ΚΟΜΨΟ',
  'ΠΙΕΖΩ',
  'ΑΣΠΡΟ',
  'ΠΑΛΤΟ',
  'ΝΟΣΟΣ',
  'ΑΛΑΝΙ',
  'ΑΜΑΞΙ',
  'ΒΡΥΣΗ',
  'ΑΙΓΛΗ',
  'ΚΟΛΙΕ',
  'ΤΡΗΜΑ',
  'ΝΟΙΚΙ',
  'ΓΙΑΤΙ',
  'ΠΛΕΟΝ',
  'ΨΩΝΙΟ',
  'ΣΚΥΛΙ',
  'ΓΡΙΠΗ',
  'ΚΡΑΤΩ',
  'ΖΩΙΚΟ',
  'ΑΠΑΝΩ',
  'ΚΟΤΣΙ',
  'ΑΜΑΞΑ',
  'ΜΙΓΑΣ',
  'ΛΙΤΡΟ',
  'ΞΟΦΛΩ',
  'ΠΕΡΣΙ',
  'ΤΡΑΒΩ',
  'ΜΕΣΟΝ',
  'ΔΑΣΟΣ',
  'ΚΩΝΟΣ',
  'ΠΤΗΣΗ',
  'ΜΟΥΡΗ',
  'ΧΝΩΤΟ',
  'ΟΡΚΟΣ',
  'ΤΡΕΧΩ',
  'ΝΟΘΟΣ',
  'ΟΠΟΤΕ',
  'ΝΟΝΟΣ',
  'ΜΑΓΟΣ',
  'ΠΗΔΑΩ',
  'ΑΚΟΜΑ',
  'ΦΥΛΑΩ',
  'ΡΗΧΟΣ',
  'ΧΩΡΑΩ',
  'ΠΑΡΛΑ',
  'ΡΟΛΟΙ',
  'ΠΑΣΧΩ',
  'ΜΗΛΙΑ',
  'ΑΙΤΙΟ',
  'ΔΗΘΕΝ',
  'ΣΟΜΠΑ',
  'ΤΑΒΛΑ',
  'ΜΑΔΑΩ',
  'ΛΟΥΚΙ',
  'ΛΙΓΔΑ',
  'ΓΥΡΝΩ',
  'ΣΩΣΤΑ',
  'ΘΑΡΡΩ',
  'ΣΑΚΙΑ',
  'ΑΡΓΟΣ',
  'ΔΙΟΤΙ',
  'ΦΑΡΣΙ',
  'ΜΗΤΡΑ',
  'ΥΓΙΗΣ',
  'ΦΥΚΟΣ',
  'ΖΕΣΤΗ',
  'ΚΟΛΛΑ',
  'ΩΡΑΙΑ',
  'ΞΕΡΝΩ',
  'ΑΠΟΡΩ',
  'ΑΝΤΙΟ',
  'ΘΥΜΟΣ',
  'ΕΚΡΟΗ',
  'ΣΚΕΛΗ',
  'ΨΩΝΙΑ',
  'ΣΟΓΙΑ',
  'ΦΕΓΓΩ',
  'ΟΛΜΟΣ',
  'ΘΗΡΑΣ',
  'ΣΦΗΚΑ',
  'ΛΕΠΤΟ',
  'ΒΡΕΧΩ',
  'ΠΛΑΝΟ',
  'ΒΑΡΚΑ',
  'ΜΑΛΛΙ',
  'ΚΡΑΖΩ',
  'ΣΟΦΟΣ',
  'ΑΝΤΛΩ',
  'ΚΟΛΛΩ',
  'ΟΡΕΞΗ',
  'ΣΚΑΡΙ',
  'ΠΗΚΤΑ',
  'ΚΡΙΟΣ',
  'ΧΡΗΜΑ',
  'ΒΕΛΟΣ',
  'ΜΕΝΤΑ',
  'ΤΡΩΤΑ',
  'ΤΣΙΠΑ',
  'ΚΑΦΕΣ',
  'ΚΩΦΟΣ',
  'ΧΝΑΡΙ',
  'ΕΙΝΑΙ',
  'ΣΜΗΝΗ',
  'ΠΟΙΟΣ',
  'ΖΗΜΙΑ',
  'ΣΑΦΗΣ',
  'ΛΕΣΧΗ',
  'ΑΨΥΧΟ',
  'ΞΥΛΙΑ',
  'ΑΜΕΛΩ',
  'ΚΟΥΤΑ',
  'ΜΠΡΟΣ',
  'ΖΟΦΟΣ',
  'ΦΕΥΓΩ',
  'ΟΧΕΙΑ',
  'ΠΕΙΝΑ',
  'ΝΕΦΤΙ',
  'ΒΑΡΥΣ',
  'ΧΟΡΤΟ',
  'ΚΛΑΔΙ',
  'ΟΡΧΙΣ',
  'ΤΑΡΤΑ',
  'ΕΞΗΓΩ',
  'ΧΑΡΤΙ',
  'ΠΟΛΟΣ',
  'ΦΥΛΛΑ',
  'ΕΥΘΥΣ',
  'ΚΟΡΜΙ',
  'ΠΕΙΡΑ',
  'ΧΩΡΙΑ',
  'ΑΛΕΘΩ',
  'ΓΚΑΦΑ',
  'ΦΟΡΟΣ',
  'ΗΣΥΧΑ',
  'ΓΔΥΝΩ',
  'ΖΥΜΕΣ',
  'ΛΟΧΟΣ',
  'ΑΡΘΡΟ',
  'ΑΛΩΣΗ',
  'ΕΝΟΧΟ',
  'ΠΑΡΩΝ',
  'ΒΡΩΜΑ',
  'ΡΩΤΑΩ',
  'ΑΠΛΩΣ',
  'ΦΟΝΤΟ',
  'ΘΥΣΙΑ',
  'ΣΚΥΛΑ',
  'ΚΑΡΜΑ',
  'ΦΩΚΙΑ',
  'ΑΒΟΛΑ',
  'ΓΚΕΛΑ',
  'ΓΡΑΦΗ',
  'ΕΚΑΤΟ',
  'ΑΙΤΟΣ',
  'ΣΚΙΖΩ',
  'ΝΙΩΘΩ',
  'ΚΟΠΟΣ',
  'ΤΟΛΜΩ',
  'ΧΥΜΟΣ',
  'ΜΠΟΡΩ',
  'ΓΕΡΟΣ',
  'ΦΟΒΙΑ',
  'ΛΟΓΙΑ',
  'ΡΗΓΜΑ',
  'ΘΡΕΨΗ',
  'ΛΟΦΟΣ',
  'ΑΚΟΜΗ',
  'ΤΑΞΗΣ',
  'ΘΕΩΡΩ',
  'ΘΕΙΟΣ',
  'ΘΕΙΚΟ',
  'ΤΕΧΝΗ',
  'ΩΜΕΓΑ',
  'ΑΠΑΤΑ',
  'ΦΙΛΙΑ',
  'ΑΨΗΤΟ',
  'ΠΡΑΞΗ',
  'ΟΒΙΔΑ',
  'ΛΑΜΠΩ',
  'ΑΕΡΑΣ',
  'ΤΡΕΝΟ',
  'ΑΚΟΠΑ',
  'ΕΧΘΕΣ',
  'ΑΥΡΙΟ',
  'ΠΛΕΝΩ',
  'ΚΡΕΜΑ',
  'ΝΥΧΙΑ',
  'ΠΕΝΝΑ',
  'ΜΙΣΟΣ',
  'ΑΡΑΙΑ',
  'ΤΟΝΟΣ',
  'ΧΑΦΤΩ',
  'ΚΕΡΜΑ',
  'ΞΕΦΤΙ',
  'ΣΟΥΠΑ',
  'ΧΥΤΗΣ',
  'ΧΑΖΟΣ',
  'ΑΔΕΙΑ',
  'ΚΥΡΙΑ',
  'ΟΓΔΟΗ',
  'ΛΕΙΟΣ',
  'ΙΣΩΜΑ',
  'ΣΤΟΜΑ',
  'ΤΕΜΠΟ',
  'ΝΩΠΟΣ',
  'ΑΡΕΝΑ',
  'ΠΛΕΚΩ',
  'ΒΕΡΓΑ',
  'ΕΝΩΝΩ',
  'ΩΣΠΟΥ',
  'ΑΜΕΣΑ',
  'ΧΩΡΟΣ',
  'ΠΛΑΣΗ',
  'ΤΡΥΠΩ',
  'ΑΞΙΟΣ',
  'ΧΛΙΔΗ',
  'ΧΑΡΟΣ',
  'ΧΑΙΡΙ',
  'ΛΙΩΝΩ',
  'ΡΩΓΜΗ',
  'ΣΦΥΡΙ',
  'ΡΗΣΗΣ',
  'ΕΝΝΟΩ',
  'ΟΜΟΙΑ',
  'ΓΕΥΜΑ',
  'ΒΙΟΛΙ',
  'ΚΥΚΝΟ',
  'ΣΠΙΘΑ',
  'ΜΑΝΑΣ',
  'ΣΦΙΞΗ',
  'ΑΒΑΤΟ',
  'ΟΥΡΙΑ',
  'ΨΥΧΡΑ',
  'ΓΑΤΟΣ',
  'ΕΞΑΓΩ',
  'ΥΠΤΙΟ',
  'ΚΟΡΦΗ',
  'ΑΓΟΡΑ',
  'ΑΤΙΜΑ',
  'ΖΩΔΙΟ',
  'ΑΣΘΜΑ',
  'ΦΘΟΝΩ',
  'ΠΑΝΤΑ',
  'ΑΣΤΡΟ',
  'ΔΟΛΟΣ',
  'ΘΡΥΛΕ',
  'ΙΠΠΟΣ',
  'ΕΧΘΡΑ',
  'ΡΥΠΟΣ',
  'ΜΥΡΤΟ',
  'ΤΟΜΕΣ',
  'ΤΣΙΤΑ',
  'ΔΕΗΣΗ',
  'ΤΥΠΑΣ',
  'ΑΤΜΟΣ',
  'ΘΗΛΙΑ',
  'ΧΑΛΑΩ',
  'ΚΥΣΤΗ',
  'ΠΟΝΟΣ',
  'ΑΣΥΛΟ',
  'ΑΖΩΤΟ',
  'ΠΟΛΥΣ',
  'ΠΙΚΡΑ',
  'ΧΑΛΙΑ',
  'ΝΥΧΤΑ',
  'ΜΑΤΙΑ',
  'ΚΙΜΑΣ',
  'ΕΠΙΖΩ',
  'ΣΑΡΠΑ',
  'ΤΕΡΜΑ',
  'ΤΡΕΦΩ',
  'ΥΓΕΙΑ',
  'ΚΙΑΛΙ',
  'ΜΙΖΑΣ',
  'ΒΩΜΟΣ',
  'ΤΡΥΖΩ',
  'ΤΡΕΙΣ',
  'ΠΡΗΖΩ',
  'ΑΓΟΝΟ',
  'ΚΡΕΠΑ',
  'ΤΡΙΤΟ',
  'ΝΕΡΟΥ',
  'ΤΡΙΚΟ',
  'ΣΧΗΜΑ',
  'ΕΙΜΑΙ',
  'ΚΕΝΤΩ',
  'ΠΕΤΡΑ',
  'ΔΗΜΟΣ',
  'ΠΛΗΡΩ',
  'ΡΕΥΣΗ',
  'ΛΟΞΟΣ',
  'ΠΕΔΙΟ',
  'ΝΙΑΤΑ',
  'ΖΑΡΕΣ',
  'ΒΟΛΟΣ',
  'ΝΙΚΕΣ',
  'ΣΚΗΝΗ',
  'ΧΕΙΛΗ',
  'ΜΥΛΟΣ',
  'ΜΗΝΑΣ',
  'ΣΤΕΦΩ',
  'ΕΚΤΟΣ',
  'ΣΤΕΓΗ',
  'ΠΟΖΕΣ',
  'ΠΝΙΓΩ',
  'ΑΦΟΒΑ',
  'ΠΛΗΘΗ',
  'ΣΦΑΓΗ',
  'ΝΟΤΟΣ',
  'ΣΧΙΖΩ',
  'ΧΤΗΜΑ',
  'ΟΔΕΥΩ',
  'ΜΕΘΑΩ',
  'ΛΙΘΟΣ',
  'ΤΟΜΟΣ',
  'ΚΡΑΣΙ',
  'ΟΝΤΩΣ',
  'ΡΑΤΣΑ',
  'ΛΑΙΔΗ',
  'ΕΠΟΧΗ',
  'ΗΠΙΟΣ',
  'ΠΛΩΡΗ',
  'ΒΑΡΟΣ',
  'ΑΤΥΠΑ',
  'ΥΨΩΣΗ',
  'ΡΟΦΟΣ',
  'ΟΡΑΜΑ',
  'ΠΕΤΣΙ',
  'ΕΠΑΝΩ',
  'ΣΤΥΨΗ',
  'ΝΕΦΟΣ',
  'ΝΙΠΤΩ',
  'ΑΝΕΣΗ',
  'ΑΡΓΙΑ',
  'ΝΥΞΙΣ',
  'ΚΟΥΒΑ',
  'ΕΘΙΖΩ',
  'ΤΖΑΚΙ',
  'ΦΙΔΕΣ',
  'ΚΥΛΑΩ',
  'ΔΙΧΩΣ',
  'ΣΕΡΝΩ',
  'ΟΑΣΗΣ',
  'ΠΟΘΕΝ',
  'ΠΥΚΝΑ',
  'ΑΥΛΟΣ',
  'ΦΑΡΣΑ',
  'ΠΗΛΟΣ',
  'ΟΡΟΦΗ',
  'ΚΟΥΠΙ',
  'ΛΥΤΡΑ',
  'ΔΟΝΤΙ',
  'ΣΟΥΖΑ',
  'ΧΤΕΝΑ',
  'ΝΤΑΛΑ',
  'ΦΥΣΗΣ',
  'ΟΡΘΩΣ',
  'ΤΑΧΥΣ',
  'ΓΟΥΡΙ',
  'ΜΟΥΣΑ',
  'ΕΛΚΟΣ',
  'ΟΜΑΔΑ',
  'ΞΕΡΕΣ',
  'ΠΛΗΞΗ',
  'ΒΓΑΖΩ',
  'ΔΙΩΡΟ',
  'ΑΘΛΟΙ',
  'ΝΑΡΚΗ',
  'ΑΔΙΚΑ',
  'ΠΑΤΑΩ',
  'ΦΑΣΜΑ',
  'ΝΟΗΤΑ',
  'ΛΑΙΜΟ',
  'ΧΟΡΔΗ',
  'ΝΤΑΗΣ',
  'ΑΓΧΟΣ',
  'ΓΚΑΜΑ',
  'ΤΥΦΟΣ',
  'ΟΞΕΙΑ',
  'ΠΟΛΛΑ',
  'ΑΛΓΟΣ',
  'ΕΙΡΩΝ',
  'ΚΑΥΣΗ',
  'ΔΑΚΡΥ',
  'ΥΨΩΝΩ',
  'ΒΛΑΒΗ',
  'ΓΑΝΤΙ',
  'ΦΕΡΝΩ',
  'ΜΟΡΙΟ',
  'ΤΡΩΓΩ',
  'ΚΟΝΤΑ',
  'ΣΚΕΨΗ',
  'ΜΑΣΑΩ',
  'ΩΡΑΙΟ',
  'ΠΙΟΤΟ',
  'ΛΑΜΠΑ',
  'ΦΩΤΟΣ',
  'ΣΟΒΑΣ',
  'ΤΖΑΜΙ',
  'ΗΧΗΡΑ',
  'ΣΠΟΡΑ',
  'ΒΙΤΣΑ',
  'ΛΑΣΠΗ',
  'ΑΝΔΡΑ',
  'ΛΥΣΣΩ',
  'ΟΙΚΟΣ',
  'ΟΧΕΤΟ',
  'ΠΡΑΜΑ',
  'ΛΑΜΔΑ',
  'ΚΥΡΟΣ',
  'ΑΘΛΙΑ',
  'ΤΕΚΝΟ',
  'ΘΗΤΗΣ',
  'ΣΤΕΨΗ',
  'ΑΘΩΟΣ',
  'ΠΕΠΛΟ',
  'ΤΡΙΧΑ',
  'ΟΠΕΡΑ',
  'ΟΥΡΑΣ',
  'ΚΩΔΩΝ',
  'ΟΜΝΥΩ',
  'ΖΩΑΚΙ',
  'ΑΠΛΕΣ',
  'ΗΡΕΜΩ',
  'ΑΚΥΡΑ',
  'ΑΞΙΝΑ',
  'ΤΕΡΨΗ',
  'ΠΕΡΝΩ',
  'ΜΠΑΛΑ',
  'ΕΓΓΥΣ',
  'ΝΤΙΒΑ',
  'ΨΩΜΑΣ',
  'ΔΕΣΜΗ',
  'ΔΕΞΙΑ',
  'ΝΤΥΝΩ',
  'ΡΑΒΔΙ',
  'ΠΙΤΣΑ',
  'ΕΞΑΡΙ',
  'ΑΓΝΟΩ',
  'ΞΕΣΠΩ',
  'ΤΣΕΠΗ',
  'ΧΕΙΡΑ',
  'ΡΟΔΕΣ',
  'ΥΨΩΜΑ',
  'ΕΣΟΧΗ',
  'ΞΥΠΝΩ',
  'ΨΟΦΟΣ',
  'ΡΙΠΤΩ',
  'ΣΧΕΣΗ',
  'ΛΑΒΡΑ',
  'ΜΥΡΩΝ',
  'ΒΟΜΒΑ',
  'ΛΙΡΕΣ',
  'ΚΑΛΩΣ',
  'ΚΕΦΙΑ',
  'ΕΝΟΧΗ',
  'ΛΑΒΕΣ',
  'ΚΡΥΒΩ',
  'ΙΣΧΥΩ',
  'ΑΓΕΛΗ',
  'ΠΤΩΣΗ',
  'ΡΕΥΜΑ',
  'ΛΑΜΨΗ',
  'ΘΡΗΝΩ',
  'ΜΙΚΡΟ',
  'ΠΕΤΑΩ',
  'ΕΞΟΔΟ',
  'ΠΟΥΡΟ',
  'ΚΛΑΨΑ',
  'ΧΩΡΙΣ',
  'ΜΟΥΣΙ',
  'ΚΛΑΙΩ',
  'ΠΑΧΥΣ',
  'ΠΙΟΝΙ',
  'ΤΑΜΑΜ',
  'ΑΙΧΜΗ',
  'ΡΕΣΤΑ',
  'ΚΛΙΝΩ',
  'ΣΠΑΖΩ',
  'ΧΩΡΕΣ',
  'ΧΟΡΟΣ',
  'ΝΤΟΥΣ',
  'ΒΑΡΑΩ',
  'ΒΟΣΚΩ',
  'ΧΡΩΜΑ',
  'ΣΚΑΦΗ',
  'ΔΟΤΗΣ',
  'ΚΛΗΣΗ',
  'ΑΕΡΙΟ',
  'ΧΡΗΣΗ',
  'ΞΙΦΟΣ',
  'ΑΤΥΧΩ',
  'ΛΑΣΚΑ',
  'ΣΑΚΟΣ',
  'ΦΩΛΙΑ',
  'ΝΕΥΜΑ',
  'ΥΠΝΟΣ',
  'ΠΕΝΘΩ',
  'ΜΥΙΚΑ',
  'ΦΡΙΚΗ',
  'ΒΡΟΜΑ',
  'ΜΑΙΟΣ',
  'ΑΝΕΤΑ',
  'ΕΟΡΤΗ',
  'ΜΥΓΩΝ',
  'ΕΘΝΟΣ',
  'ΚΑΘΩΣ',
  'ΚΑΡΤΑ',
  'ΤΑΙΡΙ',
  'ΠΑΣΑΣ',
  'ΜΗΚΟΣ',
  'ΤΟΥΠΕ',
  'ΣΚΑΒΩ',
  'ΣΟΡΟΣ',
  'ΦΟΒΟΣ',
  'ΕΞΑΔΑ',
  'ΦΡΑΣΗ',
  'ΚΡΙΜΑ',
  'ΜΕΤΡΩ',
  'ΡΙΧΝΩ',
  'ΠΡΟΚΑ',
  'ΧΕΡΙΑ',
  'ΦΤΕΡΟ',
  'ΔΙΕΣΗ',
  'ΕΞΕΧΩ',
  'ΑΓΡΟΣ',
  'ΨΙΛΟΣ',
  'ΘΟΛΟΣ',
  'ΠΟΣΟΣ',
  'ΚΑΔΡΟ',
  'ΤΥΠΟΣ',
  'ΛΟΧΙΑ',
  'ΟΝΟΜΑ',
  'ΣΥΚΙΑ',
  'ΒΙΩΝΩ',
  'ΑΡΑΓΕ',
  'ΑΦΗΝΩ',
  'ΠΗΧΗΣ',
  'ΚΑΡΦΙ',
  'ΣΤΑΧΥ',
  'ΨΟΦΙΑ',
  'ΝΑΝΟΣ',
  'ΗΘΙΚΗ',
  'ΗΛΙΟΥ',
  'ΥΨΗΛΑ',
  'ΧΗΡΟΣ',
  'ΣΠΥΡΙ',
  'ΙΝΔΟΣ',
  'ΔΙΧΤΥ',
  'ΜΗΡΟΣ',
  'ΗΡΩΟΥ',
  'ΛΕΙΠΩ',
  'ΚΑΤΩΙ',
  'ΦΤΥΜΑ',
  'ΠΙΣΤΗ',
  'ΤΡΙΒΩ',
  'ΠΙΕΣΗ',
  'ΑΠΟΨΕ',
  'ΕΛΑΦΙ',
  'ΙΛΑΡΑ',
  'ΦΕΤΟΣ',
  'ΚΛΟΠΗ',
  'ΓΝΩΣΗ',
  'ΨΥΧΟΣ',
  'ΤΙΓΡΗ',
  'ΚΑΛΜΑ',
  'ΟΡΑΤΑ',
  'ΠΑΣΧΑ',
  'ΔΥΑΔΑ',
  'ΠΕΡΑΣ',
  'ΣΤΗΝΩ',
  'ΣΤΕΝΗ',
  'ΒΟΛΤΑ',
  'ΘΗΛΥΣ',
  'ΠΑΓΚΟ',
  'ΤΙΤΛΟ',
  'ΟΡΙΖΩ',
  'ΤΑΦΟΣ',
  'ΚΑΜΠΗ',
  'ΠΑΠΑΣ',
  'ΙΩΔΙΟ',
  'ΤΑΓΚΟ',
  'ΡΗΤΟΣ',
  'ΑΠΟΧΗ',
  'ΑΜΥΝΑ',
  'ΣΤΑΡΙ',
  'ΤΕΚΕΣ',
  'ΜΕΛΟΣ',
  'ΜΙΚΤΑ',
  'ΛΟΓΧΗ',
  'ΚΟΥΤΙ',
  'ΜΠΥΡΑ',
  'ΥΜΝΟΣ',
  'ΣΤΑΛΑ',
  'ΣΑΙΤΑ',
  'ΑΨΙΔΑ',
  'ΨΑΘΑΣ',
  'ΜΙΞΗΣ',
  'ΑΤΑΚΑ',
  'ΣΥΧΝΑ',
  'ΛΟΓΟΣ',
  'ΔΙΑΒΑ',
  'ΨΑΡΑΣ',
  'ΙΑΤΡΕ',
  'ΣΑΥΡΑ',
  'ΟΧΗΜΑ',
  'ΟΡΥΧΗ',
  'ΕΝΝΙΑ',
  'ΜΙΓΜΑ',
  'ΖΥΓΟΣ',
  'ΠΑΘΟΣ',
  'ΦΤΗΝΑ',
  'ΛΙΩΜΑ',
  'ΧΕΛΙΑ',
  'ΧΑΜΟΣ',
  'ΠΟΛΗΣ',
  'ΦΤΑΝΩ',
  'ΧΑΡΜΑ',
  'ΦΗΜΕΣ',
  'ΡΙΖΕΣ',
  'ΟΓΚΟΣ',
  'ΠΑΡΕΑ',
  'ΕΞΑΨΗ',
  'ΦΑΚΟΣ',
  'ΠΑΧΝΙ',
  'ΜΕΝΟΣ',
  'ΜΟΧΘΩ',
  'ΜΠΟΡΑ',
  'ΘΕΑΜΑ',
  'ΤΕΛΜΑ',
  'ΓΙΔΙΑ',
  'ΜΠΑΖΑ',
  'ΚΡΙΝΟ',
  'ΑΤΟΜΟ',
  'ΙΔΙΩΣ',
  'ΑΛΑΤΑ',
  'ΕΡΠΗΣ',
  'ΠΕΙΘΩ',
  'ΠΟΜΠΗ',
  'ΑΛΛΟΥ',
  'ΕΝΤΟΣ',
  'ΒΟΥΤΩ',
  'ΕΥΡΥΣ',
  'ΤΟΥΦΑ',
  'ΠΑΥΛΑ',
  'ΜΟΡΙΑ',
  'ΑΠΛΟΣ',
  'ΚΕΝΟΣ',
  'ΟΧΤΟΣ',
  'ΚΛΩΣΩ',
  'ΑΛΑΝΑ',
  'ΦΑΤΣΑ',
  'ΡΥΘΜΟ',
  'ΤΕΛΟΣ',
  'ΒΑΘΙΑ',
  'ΟΧΛΟΣ',
  'ΤΟΜΟΙ',
  'ΑΝΗΚΩ',
  'ΤΡΟΠΗ',
  'ΛΟΦΙΟ',
  'ΔΕΙΝΑ',
  'ΘΛΑΣΗ',
  'ΝΕΦΡΑ',
  'ΘΥΡΑΣ',
  'ΕΦΗΒΗ',
  'ΕΞΩΣΗ',
  'ΙΣΧΙΟ',
  'ΦΤΥΝΩ',
  'ΨΗΦΙΑ',
  'ΚΤΗΜΑ',
  'ΟΒΟΛΟ',
  'ΕΦΕΣΗ',
  'ΛΙΤΡΑ',
  'ΡΟΥΜΙ',
  'ΟΓΔΟΟ',
  'ΤΣΟΧΑ',
  'ΛΑΙΚΗ',
  'ΣΚΟΝΗ',
  'ΠΡΑΣΟ',
  'ΥΛΙΚΟ',
  'ΠΙΤΕΣ',
  'ΛΙΣΤΑ',
  'ΣΑΙΝΙ',
  'ΠΑΓΟΣ',
  'ΜΑΓΙΟ',
  'ΤΖΙΝΙ',
  'ΞΙΔΙΑ',
  'ΔΙΚΙΟ',
  'ΞΕΧΝΩ',
  'ΨΗΤΟΣ',
  'ΠΛΗΓΗ',
  'ΔΕΡΜΑ',
  'ΓΕΝΙΑ',
  'ΑΔΙΚΟ',
  'ΣΤΟΦΑ',
  'ΜΟΙΡΑ',
  'ΣΑΦΩΣ',
  'ΜΗΝΥΩ',
  'ΑΛΥΚΗ',
  'ΚΛΙΜΑ',
  'ΣΚΕΤΑ',
  'ΑΜΝΟΣ',
  'ΖΗΤΑΩ',
  'ΣΤΟΛΗ',
  'ΙΔΡΥΩ',
  'ΣΙΩΠΗ',
  'ΒΟΗΘΩ',
  'ΘΑΛΠΩ',
  'ΛΙΠΟΣ',
  'ΚΡΟΥΩ',
  'ΒΟΥΛΗ',
  'ΙΕΡΟΣ',
  'ΧΑΨΙΑ',
  'ΣΑΛΤΟ',
  'ΛΥΚΟΣ',
  'ΦΘΗΝΑ',
  'ΝΟΗΣΗ',
  'ΔΥΑΡΙ',
  'ΜΕΙΞΗ',
  'ΠΡΙΖΑ',
  'ΜΗΤΗΡ',
  'ΨΗΦΙΟ',
  'ΔΕΣΜΑ',
  'ΤΡΙΤΗ',
  'ΣΕΜΝΑ',
  'ΧΡΟΙΑ',
  'ΚΕΡΝΩ',
  'ΦΤΑΙΩ',
  'ΓΚΑΖΙ',
  'ΛΥΓΜΟ',
  'ΣΠΑΛΑ',
  'ΦΙΣΚΑ',
  'ΛΙΤΟΣ',
  'ΣΦΑΖΩ',
  'ΚΑΠΟΥ',
  'ΗΛΙΟΣ',
  'ΕΠΑΦΗ',
  'ΚΟΛΠΟ',
  'ΛΑΠΑΣ',
  'ΠΛΑΚΑ',
  'ΗΠΙΩΣ',
  'ΦΟΝΟΣ',
  'ΠΗΓΕΣ',
  'ΕΞΑΛΟ',
  'ΕΡΩΤΑ',
  'ΔΟΛΙΑ',
  'ΤΟΥΛΙ',
  'ΤΥΡΙΑ',
  'ΟΙΝΟΣ',
  'ΣΑΝΟΣ',
  'ΔΑΦΝΗ',
  'ΛΙΑΖΩ',
  'ΣΜΙΓΩ',
  'ΠΟΝΑΩ',
  'ΡΑΜΜΑ',
  'ΤΡΙΒΗ',
  'ΒΡΑΔΥ',
  'ΠΡΑΟΣ',
  'ΓΥΑΛΙ',
  'ΣΕΙΡΑ',
  'ΗΡΩΑΣ',
  'ΑΦΡΟΣ',
  'ΚΥΗΣΗ',
  'ΡΟΛΟΣ',
  'ΤΑΓΜΑ',
  'ΚΡΥΦΑ',
  'ΖΩΜΟΣ',
  'ΒΙΩΜΑ',
  'ΛΩΤΟΣ',
  'ΣΚΑΛΙ',
  'ΓΥΑΛΑ',
  'ΚΥΒΟΣ',
  'ΑΝΑΣΑ',
  'ΝΗΣΟΣ',
  'ΠΛΟΙΟ',
  'ΦΡΑΓΗ',
  'ΨΑΧΝΩ',
  'ΦΛΕΓΩ',
  'ΛΑΡΔΙ',
  'ΣΤΗΛΗ',
  'ΙΚΑΝΟ',
  'ΒΑΡΙΑ',
  'ΗΜΕΡΑ',
  'ΜΑΡΚΑ',
  'ΑΛΣΟΣ',
  'ΣΥΡΜΑ',
  'ΥΠΟΨΗ',
  'ΓΕΝΟΣ',
  'ΙΧΝΟΣ',
  'ΓΥΡΟΣ',
  'ΤΙΜΙΑ',
  'ΙΔΙΟΣ',
  'ΦΙΝΟΣ',
  'ΔΙΨΑΩ',
  'ΝΑΥΛΑ',
  'ΓΟΥΛΙ',
  'ΕΚΛΥΩ',
  'ΧΤΥΠΩ',
  'ΚΑΥΤΑ',
  'ΧΑΙΤΗ',
  'ΚΑΙΚΙ',
  'ΑΦΙΞΗ',
  'ΣΑΛΙΟ',
  'ΥΑΙΝΑ',
  'ΓΕΥΣΗ',
  'ΞΕΝΟΣ',
  'ΡΟΥΧΟ',
  'ΜΗΔΕΝ',
  'ΒΑΘΟΣ',
  'ΑΠΟΨΗ',
  'ΛΑΓΝΑ',
  'ΥΔΑΤΑ',
  'ΑΝΟΙΑ',
  'ΕΝΕΣΗ',
  'ΓΕΡΝΩ',
  'ΛΥΣΣΑ',
  'ΔΕΞΗΣ',
  'ΤΡΑΤΑ',
  'ΕΞΟΧΑ',
  'ΤΙΜΑΩ',
  'ΣΤΗΘΗ',
  'ΞΙΝΟΣ',
  'ΦΙΑΛΗ',
  'ΨΗΛΟΣ',
  'ΚΗΡΟΣ',
  'ΜΕΛΙΑ',
  'ΗΤΤΩΝ',
  'ΜΗΛΟΥ',
  'ΑΡΕΣΩ',
  'ΦΙΡΜΑ',
  'ΤΡΟΠΟ',
  'ΛΟΥΖΩ',
  'ΤΕΡΑΣ',
  'ΣΤΕΚΑ',
  'ΒΡΟΧΗ',
  'ΞΥΣΤΟ',
  'ΜΕΡΟΣ',
  'ΒΑΘΡΟ',
  'ΑΝΟΧΗ',
  'ΧΥΛΟΣ',
  'ΛΟΥΡΙ',
  'ΘΕΣΗΣ',
  'ΦΑΓΙΑ',
  'ΡΗΞΗΣ',
  'ΘΡΕΦΩ',
  'ΘΗΚΕΣ',
  'ΜΕΖΕΣ',
  'ΥΠΑΓΩ',
  'ΠΥΓΜΗ',
  'ΛΑΘΡΑ',
  'ΧΑΡΑΣ',
  'ΝΕΥΡΑ',
  'ΡΩΣΟΣ',
  'ΠΥΛΩΝ',
  'ΚΟΜΗΣ',
  'ΘΕΤΟΣ',
  'ΝΙΚΑΩ',
  'ΡΕΓΚΑ',
  'ΑΡΤΟΣ',
  'ΝΤΕΦΙ',
  'ΡΟΥΦΩ',
  'ΨΗΦΟΣ',
  'ΠΙΑΝΟ',
  'ΤΟΛΜΗ',
  'ΑΙΤΙΑ',
  'ΜΑΓΚΑ',
  'ΕΞΟΧΗ',
  'ΑΡΤΙΑ',
  'ΠΑΙΖΩ',
  'ΠΤΗΝΟ',
  'ΚΛΙΚΑ',
  'ΔΑΝΟΣ',
  'ΒΛΙΤΑ',
  'ΜΑΧΗΣ',
  'ΝΕΑΡΑ',
  'ΑΜΥΛΟ',
  'ΘΑΥΜΑ',
  'ΦΕΤΕΣ',
  'ΜΗΠΩΣ',
  'ΛΑΜΙΑ',
  'ΟΣΤΩΝ',
  'ΚΑΜΨΗ',
  'ΚΟΦΤΑ',
  'ΠΡΩΗΝ',
  'ΛΑΛΙΑ',
  'ΠΤΩΜΑ',
  'ΟΡΑΣΗ',
  'ΑΤΟΠΟ',
  'ΤΙΓΚΑ',
  'ΜΝΗΜΑ',
  'ΖΕΥΞΗ',
  'ΙΣΧΥΣ',
  'ΒΛΕΨΗ',
  'ΥΒΡΙΣ',
  'ΑΦΕΣΗ',
  'ΚΕΡΔΗ',
  'ΘΩΡΙΑ',
  'ΛΑΔΙΑ',
  'ΣΟΔΑΣ',
  'ΡΟΔΙΑ',
  'ΧΑΛΚΑ',
  'ΦΟΡΕΣ',
  'ΠΟΙΝΗ',
  'ΘΑΜΠΑ',
  'ΠΙΣΤΑ',
  'ΣΦΗΝΑ',
  'ΠΟΝΤΟ',
  'ΙΕΡΕΑ',
  'ΣΤΑΣΗ',
  'ΤΟΣΟΣ',
  'ΔΡΑΜΑ',
  'ΜΑΦΙΑ',
  'ΠΗΚΤΗ',
  'ΜΑΣΚΑ',
  'ΟΥΛΗΣ',
  'ΓΟΥΝΑ',
  'ΛΟΙΠΑ',
  'ΛΕΥΚΟ',
  'ΠΑΛΙΑ',
  'ΦΡΑΠΑ',
  'ΕΛΚΥΩ',
  'ΡΥΑΚΙ',
  'ΝΩΘΡΑ',
  'ΟΡΘΙΑ',
  'ΧΩΡΙΟ',
  'ΧΡΥΣΗ',
  'ΘΛΙΒΩ',
  'ΡΕΚΟΡ',
  'ΘΗΡΕΣ',
  'ΜΟΛΙΣ',
  'ΜΥΘΟΣ',
  'ΦΘΟΡΑ',
  'ΟΔΥΝΗ',
  'ΟΔΟΥΣ',
  'ΡΑΔΙΟ',
  'ΓΥΠΑΣ',
  'ΒΟΤΚΑ',
  'ΖΗΛΙΑ',
  'ΠΕΛΜΑ',
  'ΜΠΗΓΩ',
  'ΑΚΟΥΩ',
  'ΝΟΤΕΣ',
  'ΤΟΥΤΟ',
  'ΣΑΧΛΑ',
  'ΛΙΓΟΣ',
  'ΜΟΥΡΟ',
  'ΦΥΣΑΩ',
  'ΜΥΗΣΗ',
  'ΕΝΝΕΑ',
  'ΝΟΜΟΣ',
  'ΠΟΔΙΑ',
  'ΚΛΑΣΗ',
  'ΕΣΤΙΑ',
  'ΞΗΡΟΣ',
  'ΚΟΥΦΟ',
  'ΑΝΙΣΑ',
  'ΔΡΑΣΗ',
  'ΣΠΙΤΙ',
  'ΔΙΠΛΑ',
  'ΛΟΡΔΑ',
  'ΚΑΠΩΣ',
  'ΕΣΟΔΟ',
  'ΤΥΦΛΑ',
  'ΚΕΣΕΣ',
  'ΜΕΣΟΣ',
  'ΑΧΥΡΟ',
  'ΑΛΑΤΙ',
  'ΦΡΑΖΩ',
  'ΑΥΤΟΣ',
  'ΑΛΟΓΑ',
  'ΣΠΑΘΙ',
  'ΖΕΒΡΑ',
  'ΠΑΣΤΑ',
  'ΑΠΑΛΑ',
  'ΑΓΟΡΙ',
  'ΛΙΜΝΗ',
  'ΠΙΑΤΟ',
  'ΤΕΝΤΑ',
  'ΑΡΧΕΣ',
  'ΠΑΥΣΗ',
  'ΑΛΩΝΙ',
  'ΥΔΡΟΣ',
  'ΩΑΡΙΟ',
  'ΓΕΛΙΟ',
  'ΒΟΡΙΑ',
  'ΚΕΡΙΑ',
  'ΠΙΠΤΩ',
  'ΕΛΑΤΟ',
  'ΤΡΑΝΑ',
  'ΑΡΚΕΙ',
  'ΠΑΤΟΣ',
  'ΝΑΖΙΑ',
  'ΠΛΑΤΗ',
  'ΚΟΣΜΩ',
  'ΚΛΑΡΙ',
  'ΛΕΥΚΑ',
  'ΚΟΥΝΩ',
  'ΛΙΜΠΑ',
  'ΑΓΙΟΣ',
  'ΑΦΤΙΑ',
  'ΔΙΠΛΟ',
  'ΛΕΠΡΑ',
  'ΙΔΕΕΣ',
  'ΙΧΘΥΣ',
  'ΤΡΕΜΩ',
  'ΤΕΙΝΩ',
  'ΧΤΙΖΩ',
  'ΦΩΤΙΑ',
  'ΑΜΜΟΣ',
  'ΞΥΣΤΑ',
  'ΠΑΙΔΙ',
  'ΚΟΡΝΑ',
  'ΠΑΠΙΑ',
  'ΜΥΓΕΣ',
  'ΑΓΕΡΙ',
  'ΜΑΖΕΣ',
  'ΠΑΤΣΑ',
  'ΚΑΥΚΙ',
  'ΤΗΡΑΩ',
  'ΠΕΦΤΩ',
  'ΤΟΠΟΣ',
  'ΠΑΡΤΙ',
  'ΝΕΚΡΑ',
  'ΕΛΙΕΣ',
  'ΒΗΧΑΣ',
  'ΠΕΝΕΣ',
  'ΡΙΓΕΣ',
  'ΩΔΕΙΟ',
  'ΤΥΠΩΝ',
  'ΡΗΤΡΑ',
  'ΚΛΕΒΩ',
  'ΜΠΟΤΑ',
  'ΤΡΥΠΑ',
  'ΜΕΧΡΙ',
  'ΖΟΥΜΙ',
  'ΣΚΕΥΗ',
  'ΑΔΙΚΩ',
  'ΡΑΓΕΣ',
  'ΣΧΑΡΑ',
  'ΙΣΤΟΣ',
  'ΠΟΣΤΟ',
  'ΤΑΒΛΙ',
  'ΚΑΚΙΑ',
  'ΠΑΤΗΡ',
  'ΔΩΡΕΑ',
  'ΕΞΤΡΑ',
  'ΤΥΧΕΣ',
  'ΤΕΚΝΑ',
  'ΤΥΨΗΣ',
  'ΖΑΡΙΑ',
  'ΚΑΚΟΣ',
  'ΑΦΙΣΑ',
  'ΒΡΙΖΩ',
  'ΟΨΕΙΣ',
  'ΜΑΙΕΣ',
  'ΔΕΡΝΩ',
  'ΑΡΕΤΗ',
  'ΖΩΗΡΑ',
  'ΠΟΥΛΩ',
  'ΠΡΟΒΑ',
  'ΠΟΥΛΙ',
  'ΦΩΝΕΣ',
  'ΥΦΕΣΗ',
  'ΟΜΑΛΑ',
  'ΦΙΔΙΑ',
  'ΘΗΡΙΟ',
  'ΣΚΑΚΙ',
  'ΠΥΡΓΟ',
  'ΣΤΕΚΙ',
  'ΑΘΛΟΣ',
  'ΚΡΑΤΗ',
  'ΣΙΩΠΩ',
  'ΚΝΗΜΗ',
  'ΑΝΑΒΩ',
  'ΒΑΦΛΑ',
  'ΒΥΘΟΣ',
  'ΓΛΥΦΩ',
  'ΛΗΣΤΗ',
  'ΟΡΜΑΩ',
  'ΛΟΒΟΣ',
  'ΣΤΑΖΩ',
  'ΑΝΘΟΣ',
  'ΛΙΜΟΣ',
  'ΑΛΛΟΣ',
  'ΚΡΑΜΑ',
  'ΑΟΠΛΑ',
  'ΜΕΓΑΣ',
  'ΜΙΜΟΣ',
  'ΤΑΙΖΩ',
  'ΠΟΘΟΣ',
  'ΤΑΦΩΝ',
  'ΠΕΙΝΩ',
  'ΞΙΓΚΙ',
  'ΝΕΥΡΟ',
  'ΙΣΙΟΣ',
  'ΟΠΛΩΝ',
  'ΜΑΝΙΑ',
  'ΧΝΟΤΟ',
  'ΒΟΥΛΑ',
  'ΞΑΠΛΑ',
  'ΠΟΥΡΕ',
  'ΕΝΩΣΗ',
  'ΒΟΥΝΟ',
  'ΑΓΩΓΗ',
  'ΚΗΠΟΣ',
  'ΚΛΕΙΩ',
  'ΟΙΚΙΑ',
  'ΝΥΣΤΑ',
  'ΙΠΠΟΙ',
  'ΟΧΕΥΩ',
  'ΠΡΩΤΑ',
  'ΕΠΑΓΩ',
  'ΜΙΛΑΩ',
  'ΧΕΙΛΙ',
  'ΛΕΦΤΑ',
  'ΤΡΙΖΩ',
  'ΓΟΝΟΣ',
  'ΟΡΝΕΟ',
  'ΡΟΙΔΟ',
  'ΜΟΡΦΗ',
  'ΒΡΑΖΩ',
  'ΘΕΡΙΟ',
  'ΠΛΑΝΩ',
  'ΚΑΡΓΑ',
  'ΚΙΟΛΑ',
  'ΕΥΝΟΩ',
  'ΟΘΟΝΗ',
  'ΦΙΛΟΣ',
  'ΑΡΑΖΩ',
  'ΜΝΗΜΗ',
]
