import { useEffect } from "react"

const Ad = (props: any) => {
    const { currentPath } = props

    useEffect(() => {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }, [currentPath])
    
    return (
      <div key={currentPath}>
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-3878223394765907"
          data-ad-slot="9430033819"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    )
  }

  export default Ad