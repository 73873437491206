import { BaseModal } from './BaseModal'
import { GAME_URL, GAME_DESCRIPTION, CONTACT_US } from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        {GAME_DESCRIPTION} <br></br>
        <br></br>
        {CONTACT_US}
        <a href="mailto:contact@lexoula.com" className="underline font-bold">
          contact@lexoula.com
        </a>{' '}
        <br></br>
        <br></br>
        <a href={GAME_URL} className="underline font-bold">
          {GAME_URL}
        </a>{' '}
      </p>
    </BaseModal>
  )
}
