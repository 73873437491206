import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Πως παίζεται" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Μαντέψτε τη λέξη σε 6 προσπάθειες. Μετά από κάθε προσπάθεια, το χρώμα
        των τετραγώνων θα αλλάξει επιδεικνύοντας πόσο κοντά ήταν η προσπάθεια
        σας στο στόχο.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          value="Β"
          status="correct"
          isRevealing={true}
          isCompleted={true}
        />
        <Cell value="Ο" />
        <Cell value="Υ" />
        <Cell value="Ν" />
        <Cell value="Ο" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Το γράμμα Β βρίσκεται στη λέξη και είναι στη σωστή θέση
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Ζ" />
        <Cell value="Υ" />
        <Cell
          value="Γ"
          status="present"
          isRevealing={true}
          isCompleted={true}
        />
        <Cell value="Ο" />
        <Cell value="Σ" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Το γράμμα Γ βρίσκεται στη λέξη αλλά σε λάθος θεση
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Α" />
        <Cell value="Γ" />
        <Cell value="Α" />
        <Cell value="Π" status="absent" isRevealing={true} isCompleted={true} />
        <Cell value="Η" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Το γράμμα Π δε βρίσκεται σε κανένα σημείο της λέξης
      </p>
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        * Για να δείτε το ιστορικό σας ανοίξτε το παιχνίδι με το default browser
        σας (Chrome, Safari, κλπ)
      </p>
    </BaseModal>
  )
}
