export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!
export const GAME_DESCRIPTION =
  'Το Greek Wordle είναι εδώ. Η σπαζοκεφαλιά που ξεσήκωσε τον πλανήτη, τώρα και στα Ελληνικά'
export const GAME_URL = 'https://lexoula.com'
export const GAME_HASHTAG = '#lexoula'

export const CONTACT_US = 'Επικοινωνήστε μαζί μας στο '

export const WIN_MESSAGES = ['Μπράβο', 'Καταπληκτική δουλειά', 'Συγχαρητήρια']
export const GAME_COPIED_MESSAGE =
  'Το αποτέλεσμα έχει αποθηκευτεί στο clipboard σας'
export const ABOUT_GAME_MESSAGE = 'Μάθετε περισσότερα για το παιχνίδι'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Δεν υπάρχουν αρκετά γράμματα'
export const WORD_NOT_FOUND_MESSAGE = 'Η λέξη δεν βρέθηκε'
export const HARD_MODE_ALERT_MESSAGE =
  'Η επιλογή Hard Mode μπορεί να ενεργοποιηθεί μόνο πριν από την έναρξη του παιχνιδιού!'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Η σωστή απάντηση ήταν ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Το γράμμα ${guess} πρέπει να εισαχθεί στη θέση ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Η προσπάθεια πρέπει να περιέχει το γράμμα ${letter}`
export const ENTER_TEXT = 'Καταχώρηση'
export const DELETE_TEXT = 'Διαγραφή'
export const STATISTICS_TITLE = 'Στατιστική'
export const GUESS_DISTRIBUTION_TEXT = 'Κατανομή προσπαθειών'
export const NEW_WORD_TEXT = 'Νέα λέξη σε'
export const SHARE_TEXT = 'Κοινοποίηση'
export const TOTAL_TRIES_TEXT = 'Συνολικές προσπάθειες'
export const SUCCESS_RATE_TEXT = 'Πετυχημένες προσπάθειες'
export const CURRENT_STREAK_TEXT = 'Συνεχόμενες προσπάθειες'
export const BEST_STREAK_TEXT = 'Καλύτερο σερί προσπαθειών'

export const SHARE_DAY = 'Μέρα'
export const SHARE_SCORE = 'Σκορ'
export const SHARE_SΤΡΕΑΚ = 'Σερί'

export const APP_ANDROID = 'Lexoula-Android-App'
export const APP_IOS = 'Lexoula-iOS-App'