import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import {
  GAME_HASHTAG,
  GAME_TITLE,
  GAME_URL,
  //SHARE_DAY,
  SHARE_SCORE,
  SHARE_SΤΡΕΑΚ,
  APP_ANDROID,
  APP_IOS
} from '../constants/strings'
import { getStoredIsHighContrastMode } from './localStorage'
import { loadStatsFromLocalStorage } from './localStorage'
import { UAParser } from 'ua-parser-js'

declare global {
  interface Window {
    AndroidShareHandler: any
    webkit: any
  }
}

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  handleShareToClipboard: () => void
) => {
  const GameStats = loadStatsFromLocalStorage()
  
  let parser = new UAParser()
  let deviceType = parser.getDevice().type
  let browserName = parser.getBrowser().name
  let userAgent = parser.getUA()
  const isPhone = userAgent === APP_ANDROID || userAgent === APP_IOS

  const textToShare =
    `${GAME_HASHTAG} ${solutionIndex + 1} ${'•'} ${SHARE_SCORE} ${
      lost ? 'X' : guesses.length
    }/6${isHardMode ? '*' : ''} ${'•'} ${SHARE_SΤΡΕΑΚ} ${
      GameStats === null ? 0 : GameStats.currentStreak
    }\n\n` +
    generateEmojiGrid(guesses) +
    `\n\n${isPhone ? '' : GAME_URL}`

  const shareData = { text: textToShare }

  if (
    (deviceType === 'mobile' || deviceType === 'tablet') &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  ) {
    if (browserName === 'Mozilla') {
      // Firefox Android
      navigator.clipboard.writeText(textToShare)
      handleShareToClipboard()
    } else {
      navigator.share({ text: textToShare })
    }
  } else if (userAgent === APP_ANDROID) {
    // Android App
    window.AndroidShareHandler.nativeShare(GAME_TITLE, textToShare, GAME_URL)
  } else if (userAgent === APP_IOS) {
    // iOS App
    window.webkit.messageHandlers.nativeShare.postMessage({
      title: GAME_TITLE,
      url: GAME_URL,
      textToShare: textToShare,
    })
  } else {
    // Just copy to clipboard
    navigator.clipboard.writeText(textToShare)
    handleShareToClipboard()
  }
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      const isHighContrast = getStoredIsHighContrastMode()
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              if (isHighContrast) {
                return '🟧'
              }
              return '🟩'
            case 'present':
              if (isHighContrast) {
                return '🟦'
              }
              return '🟨'
            default:
              if (localStorage.getItem('theme') === 'dark') {
                return '⬛'
              }
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
